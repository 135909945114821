import React from 'react';
import Page from '../components/Page';
import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Sponsor from '../components/Sponsor';
import {Markdown} from '../components/Markdown';
import {useSiteData} from '../hooks/useSiteData';
import {graphql, Link} from "gatsby";
import VideoPlayer from "../components/VideoPlayer";
import Seo from "../components/seo";
import imgMaintenance from "../assets/images/maintainance-ant-v2.png";

export default function maintenance() {


	return <Page orientation="portrait">



		<div className="game-box">
			<div className="game-box-inner">
				<header className="header-area">
					<div className="container">
						<div className="header-grid">
							<div className="box-1">
							</div>
							<div className="box-2"/>

	{/*						<Menu/>*/}
						</div>
					</div>
				</header>

				<div className="content" style={{paddingBottom: 91.3333}}>
					<Link to="/">
						<Logo />
					</Link>

					<h1>temporarily down for maintenance</h1>

					<p>We are performing scheduled maintenance. </p>

					<p>We should be back online shortly. </p>
					<div style={{padding: 100}}>
						<img src={imgMaintenance}  alt="Rabbit watering carrots" />
					</div>


				</div> {/* / .content */}
			</div> {/* / .game-box-inner */}
			<Sponsor />
		</div> {/* / .game-box */}
	</Page>;
}


